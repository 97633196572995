import axios from "axios";

export const dashboardService = {
  getPrePayDashboard,
  setNotifications,
  getBillingDashboard,
  paperlessOptIn,
  dismissPaperlessOptIn,
  paperlessOptOut,
  moveOutPrePay,
  creditRequestStatement,
  billingDashboardTransactions,
  billingDashboardReads
};

async function getPrePayDashboard(id) {
  return axios({
    url: process.env.VUE_APP_API_URL + "dashboard/prepay/" + id,
    method: "GET"
  });
}

async function moveOutPrePay(id, data) {
  return axios({
    url: process.env.VUE_APP_API_URL + "dashboard/prepay/" + id + "/move-out",
    method: "POST",
    data: data
  });
}

async function creditRequestStatement(id) {
  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/credit-request-statement",
    method: "POST"
  });
}

async function billingDashboardReads(id, data) {
  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/billing-read-data",
    method: "POST",
    data: data
  });
}

async function billingDashboardTransactions(id, data) {
  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/billing-transaction-data",
    method: "POST",
    data: data
  });
}

async function getBillingDashboard(id) {
  return axios({
    url: process.env.VUE_APP_API_URL + "dashboard/billing/" + id,
    method: "GET"
  });
}

async function paperlessOptIn(id) {
  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/paperless-opt-in",
    method: "POST"
  });
}

async function dismissPaperlessOptIn(id) {
  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/dismiss-paperless-opt-in",
    method: "POST"
  });
}

async function paperlessOptOut(id, applyToRelatedAccounts) {
  const data = {
    applyToRelatedAccounts: applyToRelatedAccounts
  };

  return axios({
    url:
      process.env.VUE_APP_API_URL +
      "dashboard/billing/" +
      id +
      "/paperless-opt-out",
    method: "POST",
    data: data
  });
}

async function setNotifications(
  id,
  mobile,
  isMinimum,
  isMinimumAmount,
  isNegative,
  isCutoff,
  isPayment,
  isWeekly,
  isWeeklyDay,
  isWeeklyHour
) {
  const data = {
    mobile: mobile,
    is_minimum: isMinimum,
    is_minimum_amount: isMinimumAmount,
    is_negative: isNegative,
    is_cutoff: isCutoff,
    is_payment: isPayment,
    is_weekly: isWeekly,
    is_weekly_day: isWeeklyDay,
    is_weekly_hour: isWeeklyHour
  };

  return await axios({
    url:
      process.env.VUE_APP_API_URL + "dashboard/prepay/" + id + "/notifications",
    method: "POST",
    data: data
  });
}
