<template>
  <div id="app">
    <loader />
    <Alert />
    <vue-extend-layouts />
    <footer>
      <span>2024 &copy; Data Energy Management Services</span>
      <span
        ><a href="https://dataenergy.co.uk/privacy-policy/" target="_blank"
          >Privacy Policy</a
        ></span
      >
      <span
        >w:
        <a href="https://dataenergy.co.uk/residents" target="_blank"
          >Residents Hub</a
        ></span
      >
    </footer>
  </div>
</template>

<script>
import VueExtendLayouts from "vue-extend-layout";
import Loader from "@/components/Loader.vue";
import { store } from "./store/";
import Alert from "@/components/Alert";

export default {
  name: "App",
  components: { Alert, VueExtendLayouts, Loader },
  created: function() {
    this.$http.interceptors.response.use(undefined, function(err) {
      if (
        err.response &&
        err.response.status === 401 &&
        err.response.config &&
        !err.response.config.__isRetryRequest
      ) {
        store.dispatch("logout");
      }
      throw err;
    });
  }
};
</script>

<style lang="scss">
.app.login-template {
  background-color: #eee;
  padding-top: 100px;
  min-height: 100vh;
  padding-bottom: 30px;
}

footer {
  bottom: 0px;
  text-align: left;
  background-color: #222;
  padding: 30px;
  color: white;
  font-size: 0.8rem;
  width: 100%;
  z-index: 1500;

  span {
    margin: 0px 10px;
    display: block;
  }

  a {
    color: white;
    &:visited {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:active {
      color: white;
    }
  }

  @media (min-width: 576px) {
    text-align: center;
    padding: 10px;
    position: fixed;

    span {
      display: inline-block;
    }
  }
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.element {
  input {
    display: block;
    width: 100%;
    text-align: center;
    margin: 10px 0px;
    padding: 10px 5px;
  }
}

.login {
  font-family: "Open Sans", sans-serif;
  min-height: 400px;
  width: 350px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  display: flex;
  justify-content: center;

  .icon {
    position: absolute;
    top: -45px;
    background-color: white;
    border-radius: 50%;
    padding: 8px;
  }

  h1 {
    font-weight: bold;
    font-size: 1.7rem;
  }

  h2 {
    text-align: center;
    font-size: 1.4rem;
  }

  .disabled-button {
    cursor: not-allowed;
  }
}

.grecaptcha-badge {
  bottom: 45px !important;
}
</style>
