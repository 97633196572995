const defaultTitle = "Loading";

const state = {
  active: false,
  title: defaultTitle,
  subTitle: ""
};

const getters = {};

const actions = {
  loaderInitiate({ commit }, { title, subtitle }) {
    commit("initiate_loader", { title, subtitle });
  },
  loaderCancel({ commit }) {
    commit("cancel_loader");
  }
};

const mutations = {
  initiate_loader(state, { title, subtitle }) {
    state.active = true;
    state.title = title;
    state.subTitle = subtitle;
  },
  cancel_loader(state) {
    state.active = false;
    state.title = defaultTitle;
    state.subTitle = "";
  }
};

export const loader = {
  state,
  getters,
  actions,
  mutations
};
