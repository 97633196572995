import axios from "axios";

export const approvalsService = {
  getApproval,
  approve,
  reject
};

async function getApproval(key) {
  return axios({
    url: process.env.VUE_APP_API_URL + "approval/" + key,
    method: "GET"
  });
}

async function approve(key, data) {
  return axios({
    url: process.env.VUE_APP_API_URL + "approval/" + key + "/approve",
    method: "POST",
    data: data
  });
}

async function reject(key, data) {
  return axios({
    url: process.env.VUE_APP_API_URL + "approval/" + key + "/reject",
    method: "POST",
    data: data
  });
}
