<template>
  <div
    aria-label="Loading"
    class="loader is-full-page"
    v-bind:class="{ 'is-active': isActive }"
  >
    <div class="background"></div>
    <div class="detail">
      <div>
        <svg
          viewBox="0 0 38 38"
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="128"
          stroke="#00a566"
        >
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
              <path
                d="M36 18c0-9.94-8.06-18-18-18"
                transform="rotate(267.065 18 18)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 18 18"
                  to="360 18 18"
                  dur="0.8s"
                  repeatCount="indefinite"
                ></animateTransform>
              </path>
            </g>
          </g>
        </svg>
      </div>
      <div class="title">
        {{ title }}
      </div>
      <div class="sub-title">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  computed: {
    isActive() {
      return this.$store.state.loader.active;
    },
    title() {
      return this.$store.state.loader.title;
    },
    subtitle() {
      return this.$store.state.loader.subTitle;
    }
  }
};
</script>

<style lang="scss" scoped>
.loader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 1;

  &.is-active {
    display: flex;
  }

  &.is-full-page {
    z-index: 999;
    position: fixed;
  }

  .background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    opacity: 0.8;
  }

  .detail {
    position: relative;

    .title {
      font-size: 28px;
      font-weight: bold;
      margin-top: 10px;
    }

    .sub-title {
      font-size: 20px;
    }
  }
}
</style>
