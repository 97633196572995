import Vue from "vue";
import Router from "vue-router";
import { store } from "./store/";
import EmptyRouterView from "./views/EmptyRouterView";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "account",
      component: () => import("./views/Account/Account")
    },
    {
      path: "/prepay/:payRef?",
      name: "prepay",
      props: true,
      component: () => import("./views/PrePay/Dashboard")
    },
    {
      path: "/billing/:payRef?",
      name: "billing",
      props: true,
      component: () => import("./views/Billing/Dashboard")
    },
    {
      path: "/billing-approval",
      component: EmptyRouterView,
      children: [
        {
          name: "billing-agent-approve",
          path: "approve/:reference",
          props: true,
          component: () => import("./views/Billing/AgentApproval/Approval")
        },
        {
          name: "billing-agent-reject",
          path: "reject/:reference",
          props: true,
          component: () => import("./views/Billing/AgentApproval/Reject")
        }
      ]
    },
    {
      path: "/payment-request",
      component: () => import("./views/EmptyRouterView.vue"),
      children: [
        {
          name: "view-request",
          path: ":id",
          props: true,
          component: () => import("./views/PaymentRequest.vue")
        }
      ]
    },
    {
      path: "/request-to-pay",
      component: () => import("./views/EmptyRouterView.vue"),
      children: [
        {
          name: "credit-request",
          path: ":id",
          props: true,
          component: () => import("./views/Billing/RequestToPay")
        }
      ]
    },
    {
      path: "/prepay-payment/:reference?",
      name: "PrePay-Payment",
      props: route => ({
        reference: String(route.params.reference)
      }),
      component: () => import("./views/PrePay/PayCodePayment")
    },
    {
      path: "/appointment-acceptance/:linkKey?",
      name: "Appointment-Acceptance",
      props: route => ({
        linkKey: String(route.params.linkKey)
      }),
      component: () =>
        import("./views/AppointmentAcceptance/AppointmentAcceptance")
    },
    {
      path: "/login/:payRef?",
      name: "login",
      props: true,
      component: () => import("./views/Auth/Login.vue"),
      meta: {
        layout: "auth" // name of the layout
      }
    },
    {
      path: "/activate/:activateRef",
      name: "activate",
      props: true,
      component: () => import("./views/Auth/Activate.vue"),
      meta: {
        layout: "auth" // name of the layout
      }
    },
    {
      path: "/register",
      name: "register",
      props: true,
      component: () => import("./views/Auth/Register.vue"),
      meta: {
        layout: "auth" // name of the layout
      }
    },
    {
      path: "/forgotten-password",
      name: "forgotten-password",
      props: true,
      component: () => import("./views/Auth/PasswordForgot.vue"),
      meta: {
        layout: "auth" // name of the layout
      }
    },
    {
      path: "/password-reset/:passRef",
      name: "password-reset",
      props: true,
      component: () => import("./views/Auth/PasswordReset.vue"),
      meta: {
        layout: "auth" // name of the layout
      }
    },
    {
      path: "/access",
      component: EmptyRouterView,
      children: [
        {
          name: "access-sso",
          path: "sso",
          props: true,
          component: () => import("./views/Auth/SSO/AccessSSO.vue")
        },
        {
          name: "access-logout",
          path: "sign-out",
          props: true,
          component: () => import("./views/Auth/SSO/Logout.vue")
        }
      ]
    },
    {
      path: "*",
      name: "Error",
      // component: () => import('@/pages/Error') (Optional)
      component: () => import("./views/Errors/NotFound.vue")
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page

  const authPageGroups = [
    "login",
    "register",
    "password-reset",
    "forgotten-password",
    "activate"
  ];

  const ssoPageGroups = ["access-sso"];

  const ssoPageLogoutGroup = ["access-logout"];

  const publicPageGroups = [
    "payment-request",
    "billing-approval",
    "request-to-pay",
    "prepay-payment",
    "appointment-acceptance"
  ];

  let authRequired = !authPageGroups.includes(to.path.split("/")[1]);
  const isPublicPage = publicPageGroups.includes(to.path.split("/")[1]);
  const isSSOPage = ssoPageGroups.includes(to.name);
  const isSSOLogoutPage = ssoPageLogoutGroup.includes(to.name);

  if (isPublicPage || isSSOLogoutPage) {
    return next();
  }

  const loggedIn = store.getters.isLoggedIn;

  if (authRequired && !loggedIn && isSSOPage) {
    await store.dispatch("isSSO", { returnTo: to.query.return_to });
  }

  if (authRequired && !loggedIn) {
    if (to.path !== "/") {
      return next("/login?redirect=" + to.path);
    }
    return next("/login");
  }

  if (authPageGroups.includes(to.path.split("/")[1]) && loggedIn) {
    return next("/");
  }

  if (loggedIn && !store.state.auth.updated) {
    await store.dispatch("verify");
  }

  if (to.meta.admin && !store.state.auth.isAdmin) {
    return next("/");
  }

  if (store.state.alert.active) {
    store.dispatch("bumpAlert");
  }

  next();
});
