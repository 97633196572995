import { paymentService } from "../services";
import { errorHandler } from "@/components/helpers/error-handler";

const state = {
  isPayment: false
};

const getters = {};

const actions = {
  requestPayment({ commit }, { amount, id, isFullDebt }) {
    return new Promise((resolve, reject) => {
      paymentService
        .requestPayment(amount, id, isFullDebt)
        .then(response => {
          commit("set_payment");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  requestPaymentBilling({ commit }, { amount, id, reference }) {
    return new Promise((resolve, reject) => {
      paymentService
        .requestPaymentBilling(amount, id, reference)
        .then(response => {
          commit("set_payment");
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  getPayment({ commit }, { reference }) {
    return new Promise((resolve, reject) => {
      paymentService
        .getPayment(reference)
        .then(response => {
          commit("set_payment");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getCreditPaymentRequest({ commit }, { reference }) {
    return await paymentService
      .requestCreditPayment(reference)
      .then(payment => {
        commit("set_payment");
        return payment.data;
      })
      .catch(error => {
        errorHandler.triggerError(error, true);
      });
  },
  async initiateCreditPayment({ commit }, { reference, verifier, amount }) {
    return await paymentService
      .initiateCreditPayment(reference, verifier, amount)
      .then(payment => {
        commit("set_payment");
        return payment.data;
      })
      .catch(error => {
        errorHandler.triggerError(error, true);
      });
  },
  async getPrePayCodeRequest({ commit }, { code }) {
    return await paymentService
      .requestPayCodePayment(code)
      .then(payment => {
        commit("set_payment");
        return payment.data;
      })
      .catch(error => {
        errorHandler.triggerError(error, true, true);
      });
  },
  async initiatePrePayCodePayment({ commit }, { code, verifier, amount }) {
    return await paymentService
      .initiatePayCodePayment(code, verifier, amount)
      .then(payment => {
        commit("set_payment");
        return payment.data;
      })
      .catch(error => {
        errorHandler.triggerError(error, true);
      });
  }
};

const mutations = {
  set_payment(state) {
    state.isPayment = true;
  }
};

export const payment = {
  state,
  getters,
  actions,
  mutations
};
