import { approvalsService } from "@/services";

const state = {
  dashboard: {},
  isUpdated: false
};

const getters = {};

const actions = {
  approvalGet({ commit }, { key }) {
    return new Promise((resolve, reject) => {
      approvalsService
        .getApproval(key)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  approvalApprove({ commit }, { key, data }) {
    return new Promise((resolve, reject) => {
      approvalsService
        .approve(key, data)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  approvalReject({ commit }, { key, data }) {
    return new Promise((resolve, reject) => {
      approvalsService
        .reject(key, data)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  set_updated(state) {
    state.isUpdated = true;
  }
};

export const approvals = {
  state,
  getters,
  actions,
  mutations
};
