const defaultDelay = 5000; //5 seconds

const state = {
  active: false,
  type: "info",
  round: 0,
  title: "",
  subTitle: "",
  verifier: ""
};

const getters = {};

const actions = {
  pushSuccess(
    { commit, dispatch },
    { title, subtitle, samePage, customDelay }
  ) {
    const round = samePage ? 1 : 0;
    commit("content", { title, subtitle, round });
    commit("push_success");

    dispatch("timeout", { customDelay });
  },
  pushError({ commit, dispatch }, { title, subtitle, samePage, customDelay }) {
    const round = samePage ? 1 : 0;
    commit("content", { title, subtitle, round });
    commit("push_error");

    dispatch("timeout", { customDelay });
  },
  bumpAlert({ commit }) {
    if (state.round === 1) {
      commit("clear");
      return;
    }

    commit("increase");
  },
  timeout({ commit }, { customDelay }) {
    const delay = customDelay ? customDelay * 1000 : defaultDelay;
    const verifier = Math.random()
      .toString(36)
      .slice(-10);
    commit("set_verifier", { verifier });
    setTimeout(function() {
      if (verifier === state.verifier) {
        commit("clear");
      }
    }, delay);
  }
};

const mutations = {
  content(state, { title, subtitle, round }) {
    state.active = true;
    state.title = title;
    state.subTitle = subtitle;
    state.round = round;
  },
  push_success(state) {
    state.type = "success";
  },
  push_error(state) {
    state.type = "danger";
  },
  clear(state) {
    state.active = false;
    state.title = "";
    state.subTitle = "";
    state.round = 0;
    state.verifier = "";
  },
  increase(state) {
    state.round++;
  },
  set_verifier(state, { verifier }) {
    state.verifier = verifier;
  }
};

export const alert = {
  state,
  getters,
  actions,
  mutations
};
