import axios from "axios";

export const userService = {
  login,
  loginMFACode,
  loginMFACodeResend,
  logout,
  verify,
  register,
  activate,
  passwordForgot,
  passwordReset,
  accountAssociate,
  account,
  updateEmail,
  updateMFA,
  accessSSO,
  logoutSSO
};

function login(email, password, token) {
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.VUE_APP_API_URL + "users/login",
      data: { email: email, password: password, "g-recaptcha-response": token },
      method: "POST"
    })
      .then(response => {
        const validStates = ["success", "require_mfa_code"];
        if (
          !response.data ||
          !response.data.state ||
          !validStates.includes(response.data.state)
        ) {
          throw new Error("Unable to login");
        }

        if (response.data.state === "require_mfa_code") {
          resolve(response.data);
          return;
        }

        const tokenProp = response.data.token;
        const { ...userWithoutToken } = response.data;
        setLoginState(tokenProp, userWithoutToken);

        resolve(response.data);
      })
      .catch(error => {
        localStorage.removeItem("token");

        if (!error.response) {
          reject("Unable to log you in");
        }

        if (error.response && error.response.data) {
          reject(error.response.data.message);
        }

        reject("Unable to login");
      });
  });
}

function loginMFACode(authToken, code, token) {
  return new Promise((resolve, reject) => {
    axios({
      url: process.env.VUE_APP_API_URL + "users/login-mfa-code",
      data: { authToken: authToken, code: code, "g-recaptcha-response": token },
      method: "POST"
    })
      .then(response => {
        const tokenProp = response.data.token;
        const { ...userWithoutToken } = response.data;
        setLoginState(tokenProp, userWithoutToken);

        resolve(response.data);
      })
      .catch(error => {
        localStorage.removeItem("token");

        if (!error.response) {
          reject("Unable to log you in");
        }

        if (error.response && error.response.data) {
          reject(error.response.data.message);
        }

        reject("Unable to login");
      });
  });
}

async function loginMFACodeResend(authToken, token) {
  const data = {
    authToken: authToken,
    "g-recaptcha-response": token
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/login-mfa-resend",
    method: "POST",
    data: data
  });
}

function setLoginState(tokenProp, userWithoutToken) {
  localStorage.setItem("token", tokenProp);
  localStorage.setItem("user", JSON.stringify(userWithoutToken));
  axios.defaults.headers.common["Authorization"] = "Bearer " + tokenProp;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  delete axios.defaults.headers.common["Authorization"];
}

async function verify() {
  return await axios({
    url: process.env.VUE_APP_API_URL + "users/verify",
    method: "GET"
  });
}

async function account() {
  return axios({
    url: process.env.VUE_APP_API_URL + "users/account",
    method: "GET"
  });
}

async function register(email, password, passwordConfirm, token) {
  const data = {
    email: email,
    password: password,
    password_confirmation: passwordConfirm,
    "g-recaptcha-response": token
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/create",
    method: "POST",
    data: data
  });
}

async function activate(key, token) {
  const data = {
    key: key,
    "g-recaptcha-response": token
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/activate",
    method: "POST",
    data: data
  });
}

async function passwordForgot(email, token) {
  const data = {
    email: email,
    "g-recaptcha-response": token
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/password-forgotten",
    method: "POST",
    data: data
  });
}

async function passwordReset(key, password, passwordConfirm, token) {
  const data = {
    key: key,
    password: password,
    password_confirmation: passwordConfirm,
    "g-recaptcha-response": token
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/password-reset",
    method: "POST",
    data: data
  });
}

async function accountAssociate(paypoint, pin) {
  const data = {
    paypoint: paypoint,
    pin: pin
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/account-associate",
    method: "POST",
    data: data
  });
}

async function updateEmail(email, password) {
  const data = {
    password: password,
    email: email
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/update-email",
    method: "POST",
    data: data
  });
}

async function updateMFA(isEnabled, password) {
  const data = {
    isEnabled: isEnabled,
    password: password
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "users/update-mfa",
    method: "POST",
    data: data
  });
}

async function accessSSO() {
  return axios({
    url: process.env.VUE_APP_API_URL + "sso/access",
    method: "GET"
  });
}

async function logoutSSO(kind, message) {
  const data = {
    kind,
    message
  };

  return axios({
    url: process.env.VUE_APP_API_URL + "sso/log-out",
    method: "POST",
    data: data
  });
}
