import axios from "axios";

export const appointmentService = {
  getAcceptanceStatus,
  confirmAttendance
};

async function getAcceptanceStatus(linkKey) {
  return axios({
    url: process.env.VUE_APP_API_URL + "appointment/get-acceptance-status",
    method: "POST",
    data: {
      linkKey: linkKey
    }
  });
}

async function confirmAttendance(data) {
  return axios({
    url: process.env.VUE_APP_API_URL + "appointment/confirm-attendance",
    method: "POST",
    data
  });
}
