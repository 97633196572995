import axios from "axios";

export const paymentService = {
  requestPayment,
  requestPaymentBilling,
  getPayment,
  requestCreditPayment,
  initiateCreditPayment,
  requestPayCodePayment,
  initiatePayCodePayment,
  confirmCreditPayment
};

async function requestPayment(amount, id, isFullDebt) {
  return await axios({
    url: process.env.VUE_APP_API_URL + "payment/request-payment",
    method: "POST",
    data: { amount: amount, account: id, isFullDebt: isFullDebt }
  });
}

async function requestPaymentBilling(amount, id, reference) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/request-payment-billing",
    method: "POST",
    data: {
      amount: amount,
      account: id,
      billReference: reference
    }
  });
}

async function requestCreditPayment(reference) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/request-credit-payment",
    method: "POST",
    data: {
      reference: reference
    }
  });
}

async function initiateCreditPayment(reference, verifier, amount) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/initiate-credit-payment",
    method: "POST",
    data: {
      reference: reference,
      verifier: verifier,
      amount: amount
    }
  });
}

async function requestPayCodePayment(code) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/request-prepay-code-payment",
    method: "POST",
    data: {
      code: code
    }
  });
}

async function initiatePayCodePayment(code, verifier, amount) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/initiate-prepay-code-payment",
    method: "POST",
    data: {
      code: code,
      verifier: verifier,
      amount: amount
    }
  });
}

async function confirmCreditPayment(reference, amount) {
  return axios({
    url: process.env.VUE_APP_API_URL + "payment/confirm-credit-payment",
    method: "POST",
    data: {
      reference: reference,
      amount: amount
    }
  });
}

async function getPayment(reference) {
  return await axios({
    url: process.env.VUE_APP_API_URL + "payment/get-request",
    method: "POST",
    data: { reference: reference }
  });
}
