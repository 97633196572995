import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { loader } from "./loader.module";
import { alert } from "./alert.module";
import { payment } from "./payment.module";
import { dashboard } from "./dashboard.module";
import { approvals } from "@/store/approvals.module";
import { appointment } from "./appointment.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    auth,
    loader,
    payment,
    dashboard,
    approvals,
    appointment
  }
});
