import { store } from "@/store";

export const errorHandler = {
  triggerSuccess,
  triggerError
};

function triggerSuccess(title, subtitle, isSamePage) {
  const samePage = !!isSamePage;
  return store.dispatch("pushSuccess", { title, subtitle, samePage });
}

function triggerError(error, throwError, suppressError) {
  let err = error;
  if (error.response) {
    err = error.response.data.message;
  }
  if (!err) {
    err = "Unable to process";
  }
  const title = "Invalid data";
  const subtitle = "There was a problem: " + err;
  const samePage = true;
  if (!suppressError) {
    store.dispatch("pushError", { title, subtitle, samePage });
  }

  if (throwError) {
    throw err;
  }

  return false;
}
