import { appointmentService } from "../services";
import { errorHandler } from "@/components/helpers/error-handler";

const state = {
  isAppointment: false,
  linkData: []
};

const getters = {};

const actions = {
  getAcceptanceStatus({ commit }, { linkKey }) {
    return new Promise((resolve, reject) => {
      appointmentService
        .getAcceptanceStatus(linkKey)
        .then(response => {
          commit("set_appointment_status", {
            linkData: response.data
          });
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            error = error.response.data.message;
          }
          reject(error);
        });
    });
  },
  async confirmAttendance({ commit }, { data }) {
    return await appointmentService
      .confirmAttendance(data)
      .then(appointment => {
        commit("confirm_attendance");
        return appointment.data;
      })
      .catch(error => {
        errorHandler.triggerError(error, true);
      });
  }
};

const mutations = {
  set_appointment_status(state, { linkData }) {
    state.isAppointment = true;
    state.linkData = linkData;
  },
  confirm_attendance() {
    state.isAppointment = true;
  }
};

export const appointment = {
  state,
  getters,
  actions,
  mutations
};
