import axios from "axios";

export const messagesService = {
  showPublicMessages
};

async function showPublicMessages() {
  return await axios({
    url: process.env.VUE_APP_API_URL + "users/messages/generic",
    method: "GET"
  });
}
