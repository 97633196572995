import { dashboardService } from "../services";

const state = {
  dashboard: {},
  isUpdated: false
};

const getters = {};

const actions = {
  dashboardPrePayMoveOut({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .moveOutPrePay(id, data)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardCreditRequestStatement({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .creditRequestStatement(id)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardBillingReads({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .billingDashboardReads(id, data)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardBillingTransactions({ commit }, { id, data }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .billingDashboardTransactions(id, data)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardPrePayGet({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .getPrePayDashboard(id)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardBillingGet({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .getBillingDashboard(id)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  paperlessOptIn({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .paperlessOptIn(id)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dismissPaperlessOptIn({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .dismissPaperlessOptIn(id)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  paperlessOptOut({ commit }, { id, applyToRelatedAccounts }) {
    return new Promise((resolve, reject) => {
      dashboardService
        .paperlessOptOut(id, applyToRelatedAccounts)
        .then(response => {
          commit("set_updated");
          resolve(response.data);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  dashboardNotifications(
    { commit },
    {
      id,
      mobile,
      isMinimum,
      isMinimumAmount,
      isNegative,
      isCutoff,
      isPayment,
      isWeekly,
      isWeeklyDay,
      isWeeklyHour
    }
  ) {
    return new Promise((resolve, reject) => {
      dashboardService
        .setNotifications(
          id,
          mobile,
          isMinimum,
          isMinimumAmount,
          isNegative,
          isCutoff,
          isPayment,
          isWeekly,
          isWeeklyDay,
          isWeeklyHour
        )
        .then(response => {
          resolve(response.data);
          commit("set_updated");
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  set_updated(state) {
    state.isUpdated = true;
  }
};

export const dashboard = {
  state,
  getters,
  actions,
  mutations
};
