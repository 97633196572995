import Vue from "vue";

import VueGtag from "vue-gtag";

import {
  LayoutPlugin,
  AlertPlugin,
  TablePlugin,
  ButtonPlugin,
  FormPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  InputGroupPlugin,
  PopoverPlugin,
  FormCheckboxPlugin,
  SpinnerPlugin,
  TabsPlugin,
  CardPlugin
} from "bootstrap-vue";
Vue.use(LayoutPlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(TablePlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(PopoverPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueMoment from "vue-moment";

import VueScrollTo from "vue-scrollto";

import { router } from "./router";
import { store } from "./store/";

Vue.config.productionTip = false;

Vue.use(VueSweetalert2);
Vue.use(VueScrollTo);

Vue.use(VueMoment);

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_TAG },
    includes: [{ id: process.env.VUE_APP_GOOGLE_TAG_GA4 }]
  },
  router
);

Vue.prototype.$http = Axios;
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token;
}

import App from "./App.vue";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
