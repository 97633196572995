<template>
  <div class="alert">
    <div v-if="isActive">
      <b-alert v-bind:variant="type" show>
        <div class="title">{{ title }}</div>
        <div class="subtitle">{{ subtitle }}</div>
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  computed: {
    isActive() {
      return this.$store.state.alert.active;
    },
    title() {
      return this.$store.state.alert.title;
    },
    subtitle() {
      return this.$store.state.alert.subTitle;
    },
    type() {
      return this.$store.state.alert.type;
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 10px;
  right: 10px;
  text-align: left;
  z-index: 500;

  .title {
    font-weight: bold;
  }
}
</style>
