import { userService } from "../services";
import { messagesService } from "../services";
import { router } from "../router";

const state = {
  token: localStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || {},
  updated: false,
  accounts: null,
  lastVerified: null,
  firstRequest: false,
  ssoRequest: false,
  ssoReturn: null,
  messages: []
};

const getters = {
  isLoggedIn: state => !!state.token
};

const actions = {
  login({ dispatch }, { email, password, token }) {
    return new Promise((resolve, reject) => {
      userService
        .login(email, password, token)
        .then(response => {
          if (response.state === "require_mfa_code") {
            resolve(response);
            return;
          }

          const { token, ...userWithoutToken } = response;
          dispatch("commitLogin", {
            token,
            userWithoutToken,
            accounts: userWithoutToken.accounts
          });

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  loginMFACode({ dispatch }, { authToken, code, token }) {
    return new Promise((resolve, reject) => {
      userService
        .loginMFACode(authToken, code, token)
        .then(response => {
          const { token, ...userWithoutToken } = response;
          dispatch("commitLogin", {
            token,
            userWithoutToken,
            accounts: userWithoutToken.accounts
          });

          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  commitLogin({ commit }, { token, userWithoutToken, accounts }) {
    commit("auth_success", { token, userWithoutToken });
    commit("set_accounts", { accounts });
    commit("first_request");
  },
  resetFirstRequest({ commit }) {
    commit("reset_first_request");
  },
  register({ commit }, { email, password, passwordConfirm, token }) {
    return new Promise((resolve, reject) => {
      userService
        .register(email, password, passwordConfirm, token)
        .then(create => {
          commit("update_auth");
          resolve(create);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  loginMFACodeResend({ commit }, { authToken, token }) {
    return new Promise((resolve, reject) => {
      userService
        .loginMFACodeResend(authToken, token)
        .then(() => {
          commit("update_auth");
          resolve();
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  activate({ commit }, { key, token }) {
    return new Promise((resolve, reject) => {
      userService
        .activate(key, token)
        .then(create => {
          commit("update_auth");
          resolve(create);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  passwordForgot({ commit }, { email, token }) {
    return new Promise((resolve, reject) => {
      userService
        .passwordForgot(email, token)
        .then(create => {
          commit("update_auth");
          resolve(create);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  updateEmail({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      userService
        .updateEmail(email, password)
        .then(updated => {
          commit("update_auth");
          resolve(updated);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  updateMFA({ commit, dispatch }, { isEnabled, password }) {
    return new Promise((resolve, reject) => {
      userService
        .updateMFA(isEnabled, password)
        .then(updated => {
          dispatch("updateLocalMFAState", { isEnabled });
          commit("update_auth");
          resolve(updated);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  accessSSO({ commit }) {
    return new Promise((resolve, reject) => {
      userService
        .accessSSO()
        .then(updated => {
          commit("update_auth");
          resolve(updated);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  logoutSSO({ commit }, { kind, message }) {
    return new Promise((resolve, reject) => {
      userService
        .logoutSSO(kind, message)
        .then(updated => {
          commit("update_auth");
          resolve(updated);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  updateLocalMFAState({ commit }, { isEnabled }) {
    let local = JSON.parse(localStorage.getItem("user"));
    local.is_mfa = isEnabled;
    localStorage.setItem("user", JSON.stringify(local));

    commit("update_user", { user: local });
  },
  passwordReset({ commit }, { key, password, passwordConfirm, token }) {
    return new Promise((resolve, reject) => {
      userService
        .passwordReset(key, password, passwordConfirm, token)
        .then(create => {
          commit("update_auth");
          resolve(create);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  accountAssociate({ commit }, { paypoint, pin }) {
    return new Promise((resolve, reject) => {
      userService
        .accountAssociate(paypoint, pin)
        .then(account => {
          const accounts = account.data.accounts;
          commit("update_auth");
          commit("set_accounts", { accounts });
          resolve(account);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  account({ commit }) {
    return new Promise((resolve, reject) => {
      userService
        .account()
        .then(account => {
          const accounts = account.data.accounts;
          commit("set_accounts", { accounts });
          resolve(account);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
    router.push("/login");
  },
  isSSO({ commit }, { returnTo }) {
    commit("setSSO", { returnTo });
    return true;
  },
  verify({ commit }) {
    return new Promise(resolve => {
      userService
        .verify()
        .then(response => {
          commit("update_auth");
          resolve(response.data);
        })
        .catch(function() {
          userService.logout();
          commit("logout");
          router.push("/login");
        });
    });
  },
  showPublicMessages({ commit }) {
    return new Promise((resolve, reject) => {
      messagesService
        .showPublicMessages()
        .then(response => {
          let messages = response.data;
          commit("set_messages", { messages: messages });
          resolve(response);
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data.message);
          }
          reject(error);
        });
    });
  }
};

const mutations = {
  update_auth(state) {
    state.updated = true;
  },
  update_user(state, { user }) {
    state.user = user;
  },
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, { token, userWithoutToken }) {
    state.token = token;
    state.user = userWithoutToken;
  },
  set_accounts(state, { accounts }) {
    state.accounts = accounts;
  },
  auth_error(state) {
    state.status = "error";
  },
  first_request(state) {
    state.firstRequest = true;
  },
  reset_first_request(state) {
    state.firstRequest = false;
  },
  logout(state) {
    state.user = {};
    state.token = "";
    state.isAdmin = null;
    state.ssoRequest = false;
  },
  setSSO(state, { returnTo }) {
    state.ssoRequest = true;
    state.ssoReturn = returnTo;
  },
  resetSSO(state) {
    state.ssoRequest = false;
    state.ssoReturn = null;
  },
  set_admin(state, { adminState }) {
    state.isAdmin = adminState;
  },
  set_version(state, { versionDate }) {
    state.versionDate = new Date(parseInt(versionDate)).toUTCString();
  },
  set_stats(state, { stats }) {
    state.utc = new Date(parseInt(stats.utc)).toUTCString();
    state.stats = stats;
  },
  set_messages(state, { messages }) {
    state.messages = messages;
  }
};

export const auth = {
  state,
  getters,
  actions,
  mutations
};
